.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  height: 4px; /* Set the height of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #000; /* Set the color of the thumb */
  border-radius: 5px; /* Set the radius of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #747474; /* Set the color of the track */
}


.swiper-pagination-clickable .swiper-pagination-bullet {
  background: #000 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.swiper-button-next:after{
  color: #000000 ;
  font-size: calc(0.5rem + 1vw) !important;
  padding-right: 50%;
}

.swiper-wrapper{
  height: 100% !important;
  width: fit-content !important;
}


.swiper-button-prev:after{
  color: #000000 ;
  font-size: calc(0.5rem + 1vw) !important;
  padding-left: 100%;
}
@media screen and (max-width: 1500px) {
  .swiper-button-next:after{
    padding-right: 0%;
  }
  
  .swiper-button-prev:after{
    padding-left: 0%;
  }
}