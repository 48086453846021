.container{
    background-color: #fff;
    height: 100%;
}

.text{
    color: #000;
    margin: auto;
    font-family: Raleway;
}

.logo{
    width: 50%;
    justify-self: center;
    margin-left: 25%;
}