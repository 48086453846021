.footer_desktop_con{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: calc(0.7rem + 0.2vw);
  letter-spacing: 0.01em;
  color: #000;
  max-width: 1900px;
  margin: auto;
}

.footer_logo{
  position: relative;
  width: fit-content;
  margin: auto;
  height: fit-content;
}

.logoo{
  width: 10em;
  height: auto;
  margin: auto;
}

.iconContainer{
  width: 2em;
  height: 2em;
  border-radius: 50%;
  color: #000;
  cursor: pointer;
  background-color: #E5E5E5;
}
.footer_content{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  background-color: #E5E5E5;

}
.content{
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 2em;
  font-weight: 600;
  padding-left: 4em;
}
.content1{
  width: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 7em;
  padding-right: 4em;
  font-weight: 600;
}



/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 750px) {
  .footer_content{
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    background-color: #E5E5E5;
  
  }
  .content{
    padding-left: 0;
  }
  .content1{
    padding-right: 0;
  }
  .permalinks__options{
    margin: 0.5em 0;
  }
}